import { ErrorInfo } from 'react';

import * as Sentry from '@sentry/nextjs';
import { RewriteFrames } from '@sentry/integrations';

import packageInfo from '../../package.json';
import { ClientApp } from '../types';
import { SENTRY_DSN } from '../config';
import { isLocalDevelopment, isProduction, isStaging, getDeploymentCommitSHA } from './helper';

const ENABLED_ON_DEV = false;
const IS_DOCKER = !isLocalDevelopment();
const IS_STAGING = isStaging();
const IS_PRODUCTION = isProduction();
const COMMIT_SHA = getDeploymentCommitSHA();

export const initializeSentry = async (appName: ClientApp): Promise<void> => {
    const sentryDsn = SENTRY_DSN[appName];

    if (!sentryDsn && !!IS_DOCKER) {
        // eslint-disable-next-line no-console
        console.warn('Sentry Initialization failed - no sentryDsn');
        return;
    }

    if (!Sentry) {
        // eslint-disable-next-line no-console
        console.warn('Sentry Initialization failed - no Sentry');
        return;
    }

    if ((IS_DOCKER && (IS_PRODUCTION || IS_STAGING)) || ENABLED_ON_DEV) {
        Sentry.init({
            dsn: sentryDsn,
            integrations: [new RewriteFrames()],
            environment: IS_PRODUCTION ? 'production' : 'staging',
            release: packageInfo.version,
            tracesSampleRate: 0.001,
            initialScope: {
                tags: {
                    commitSHA: COMMIT_SHA,
                },
            },
            // Decluttering Sentry logs
            // Refer https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
            ignoreErrors: [
                'top.GLOBALS',
                'fb_xd_fragment',
                '_avast_submit',
                'ga is not defined',
                "Can't find variable: ga",
                'NetworkError when attempting to fetch resource.',
                'Load failed',
                'Failed to fetch',
                'Service Worker script execution timed out',
                "Cannot read properties of undefined (reading 'callbacks')",
                'Error: Script error.',
                /(ServiceWorker)+/g,
                /(Loading chunk [0-9]+ failed)+/g,
                "Can't find variable: _AutofillCallbackHandler",
                'ResizeObserver loop limit exceeded',
                /(service-worker\.js)+/g,
                'processRandomSelector is not defined',
                'Non-Error promise rejection captured with keys: currentTarget, detail, isTrusted, target',
                'Invalid state: Unable to enqueue',
                'globalThis is not defined',
                'top.GLOBALS',
                // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
                'originalCreateNotification',
                'canvas.contentDocument',
                'MyApp_RemoveAllHighlights',
                'http://tt.epicplay.com',
                "Can't find variable: ZiteReader",
                'jigsaw is not defined',
                'ComboSearch is not defined',
                'http://loading.retry.widdit.com/',
                'atomicFindClose',
                // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
                // reduce this. (thanks @acdha)
                // See http://stackoverflow.com/questions/4113268
                'bmi_SafeAddOnload',
                'EBCallBackMessageReceived',
                // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
                'conduitPage',

                // TEmporarly ignore these type of errors
                'Unknown root exit status',
                /^TypeError: null is not an object \(evaluating 'document\.querySelector\('\.ProductPrice_sellingPrice.*$/g,
                "Unexpected token '*='. Expected opening '{' at the start of a class body",
            ],

            denyUrls: [
                //  Google flakiness
                /\/(gtm|ga|analytics)\.js/i,
                // Facebook flakiness
                /graph\.facebook\.com/i,
                // Facebook blocked
                /connect\.facebook\.net\/en_US\/all\.js/i,
                // Woopra flakiness
                /eatdifferent\.com\.woopra-ns\.com/i,
                /static\.woopra\.com\/js\/woopra\.js/i,
                // Chrome extensions
                /extensions\//i,
                /^chrome:\/\//i,
                // Firefox extensions
                /^resource:\/\//i,
                // Other plugins
                /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
                /webappstoolbarba\.texthelp\.com\//i,
                /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
                // Google Adsense
                /pagead\/js/i,
            ],
        });
    }
};

export const reportError = (error: Error | unknown, errorInfo?: ErrorInfo): void => {
    Sentry.withScope((scope: Sentry.Scope) => {
        if (errorInfo) {
            Object.keys(errorInfo).forEach((key: string) => {
                scope.setExtra(key, errorInfo.componentStack);
            });
        }

        Sentry.captureException(error);
    });

    if (!IS_DOCKER) {
        // eslint-disable-next-line no-console
        console.error(error, errorInfo);
    }
};

export const reportEvent = (
    msg: string,
    logger: string,
    severity: Sentry.SeverityLevel,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    extra?: Record<string, any> | string,
): void => {
    const message = msg;
    const level = severity;

    Sentry.captureEvent({ message, extra: { extra, logger }, level });

    if (!IS_DOCKER) {
        if (extra) {
            // eslint-disable-next-line no-console
            console.warn(message, logger, extra);
        } else {
            // eslint-disable-next-line no-console
            console.warn(message, logger);
        }
    }
};
