export enum ERRORVIEW {
    GENERIC_ERROR = 'generic',
    EMPTY_ADDRESSES = 'empty_addresses',
    EMPTY_BAG_IN_CHECKOUT = 'empty_bag_in_checkout',
    EMPTY_WISHLIST = 'empty_wishlist',
    EMPTY_WARDROBE = 'empty_wardrobe',
    EMPTY_CREDITS = 'empty_credits',
    EMPTY_EXCHANGES = 'empty_exchanges',
    EMPTY_RETURNS = 'empty_returns',
    EMPTY_ELIGIBLE_ITEMS_FOR_EXCHANGE = 'empty_exchangeable_items',
    EMPTY_ELIGIBLE_ITEMS_FOR_RETURN = 'empty_returnable_items',
    EMPTY_ORDERS = 'empty_orders',
    EMPTY_SAVED_CARDS = 'empty_saved_cards',
    EMPTY_USER_COUPONS = 'empty_user_coupons',
    ORDER_ERROR = 'order_error',
    CATALOG_NOT_FOUND = ' catalog_not_found',
    NO_SEARCH_RESULT = 'no_search_result',
    OTP_TRIGGER_FAILURE = 'otp_trigger_failure',
    NO_FOLLOWED_BRANDS = 'no_followed_brands',
}

export const COOKIE_ID = {
    X_ADDRESS_KEY: 'x-address-key',
    OLD_NAMSHI_LOCALE: 'locale-v1',
    NOON_IDENTITY: '_nrtnetid',
    NAMSHI_IDENTITY: 'identity',
    NOON_LOCALE: 'nloc',
    LAT: 'X-Lat',
    LNG: 'X-Lng',
    LOC_ID: 'X-LocId',
    DELIVERY_ZONE_CODE: 'X-Delivery-Zone',
    // Header Department Tabs specific cookies
    SELECTED_DEPARTMENT_ID: 'selectedDepartmentId',
    SELECTED_SUBDEPARTMENT_ID: 'selectedSubDepartmentId',
    OLD_IS_INSTALL_APP_NUDGE_DISMISSED: 'isInstallAppNudgeDismissed', // deprecated
    IS_INSTALL_APP_NUDGE_DISMISSED: 'installAppNudgeDismissed',
} as const;

// Items to be maintained in localStorage
export const LOCALSTORAGE_ID = {
    RECENTLY_VIEWED_ITEMS: 'recentlyViewedItems',
    RECENTLY_SEARCHED_TEXTS: 'recentlySearchedTexts',
    IS_PROMO_HIDDEN: 'isPromoHidden',
} as const;

export const ROUTES = {
    HOME: '/',
    COUNTRY_SELECTION: 'home',
    BAG: 'bag',
    CHECKOUT: 'checkout',
    WISHLIST: 'wishlist',
    WARDROBES: 'wardrobes',
    CATEGORY: 'category',
    BRANDS: 'brands',
} as const;

export const SCREEN_SIZES = {
    // NOTE: This needs to be kept in sync with _mixins.scss
    screenMob: 500,
    screenMinSm: 768,
    screenMinMd: 992,
    screenMinLg: 1200,
    screenMinXlg: 1366,
    screenMinXxlg: 1441,
};

// Used in case of root layout - that caters to CountrySelection page
export const DEFAULT_LAYOUT_PROPERTIES = {
    dir: 'ltr',
    lang: 'en',
};

export const RESET_PAGE_NUMBER = '1';
export const DEFAULT_DIRECTION = 'ltr';

export enum ORDER_HOLD_TEXT_VARIANTS {
    orderListing = 'order_listing',
    orderDetail = 'order_detail',
}

export const WALLET_CURRENCY = [
    {
        label: 'AED',
        value: 'AED',
    },
    {
        label: 'SAR',
        value: 'SAR',
    },
    {
        label: 'USD',
        value: 'USD',
    },
];
