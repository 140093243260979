import { isEqual, isNumber, isString } from 'lodash';

import { CatalogResponse, NavDepartmentsResponse, ProductVariant, ProductWishlistResponse, SectionLayout } from '@nm-namshi-frontend/services';
import { Timer } from '@nm-namshi-frontend/services/models/Timer';
import { TConversionRates } from '@nm-namshi-frontend/services/models/ConversionRates';
import { TOtherCountryCurrencyEN, TSuperProduct } from '@nm-namshi-frontend/core/types';
import { COOKIE_ID } from '@nm-namshi-frontend/core/constants/uiConstants';
import { ProductFullResponse } from '@nm-namshi-frontend/services/models/ProductFullResponse';
import { APP_NUDGE_URL_PARAMS } from '@nm-namshi-frontend/core/config';

/* eslint-disable no-underscore-dangle */
export function isStaging(env = process.env.NEXT_PUBLIC_ENV || process.env._ENV) {
    return env === 'staging';
}
export function isProduction(env = process.env.NEXT_PUBLIC_ENV || process.env._ENV) {
    return env === 'production' || env === 'prod';
}
export function isLocalDevelopment() {
    // Local development is not in a docker container
    return !process.env.IS_DOCKER;
}

export function isCloudRun() {
    return process.env._DEPLOYED_LOCATION === 'cloudrun';
}

export function getDeploymentCommitSHA() {
    return process.env.COMMIT_SHA;
}

export const isBrowser = () => ![typeof window, typeof document].includes('undefined');

export const isServer = () => !isBrowser();

export const isSchmatalog = () => process.env._APP === 'schmatalog';
export const isBigalog = () => process.env._APP === 'bigalog';
export const isAccount = () => process.env._APP === 'account';
export const isCMS = () => process.env._APP === 'cms';

/**
 * Parses any arabic digits
 * @param  {any} digit - The digit typed by the user
 * @returns {any} - parsed digit
 */
export const parseArabic = (digit: string): string => {
    const formattedDigit = digit
        .replace(/[٠١٢٣٤٥٦٧٨٩]/g, (d: string) => `${d.charCodeAt(0) - 1632}`)
        .replace(/[۰۱۲۳۴۵۶۷۸۹]/g, (d: string) => `${d.charCodeAt(0) - 1776}`)
        .replace(/ /g, '');

    return formattedDigit;
};

/**
 * Removes special characters from text
 * @param  {string} text - Text to be sanitized
 * @returns {string} - Sanitized text
 */
export const sanitizeText = (text: string): string =>
    text.replace(/</g, '').replace(/>/g, '').replace(/%/g, '').replace(/=/g, '').replace(/"/g, '');

export const truncateWithEllipsis = (text = '', limit = 50, threshold = 5): string =>
    text.length > limit + threshold ? `${text.substr(0, limit).trim()}...` : text;

/**
 * Creates new cookie
 * @param  {string} key                   The key or identifier for the cookie
 * @param  {string} value                 Contents of the cookie
 * @param  {{maxAge: number}} options     Cookie options (currently only factors in max-age)
 * @returns {void}
 */
export const setCookie = (key: string, value: any, options?: { maxAge: number }): void => {
    if (isServer()) {
        console.error('🚨 Accessing cookies from server');
        return;
    }

    const expiry = isNumber(options?.maxAge) ? `max-age=${options?.maxAge}` : `expires=Fri, 31 Dec 9999 23:59:59 GMT`;
    const hostname = window.location.hostname.split('.').slice(-2).join('.');

    document.cookie = `${key}=${value}; ${expiry}; Secure; Path=/;domain=.${hostname}`;
};

/**
 * Creates removes cookie
 * @param  {string} key                   The key or identifier for the cookie
 * @returns {void}
 */
export const deleteCookie = (key: string): void => {
    if (isServer()) {
        console.error('🚨 Accessing cookies from server');
        return;
    }

    const expiry = `expires=Fri, 01 Jan 1970 00:00:00 GMT`;
    const hostname = window.location.hostname.split('.').slice(-2).join('.');

    document.cookie = `${key}=''; ${expiry}; Secure; Path=/;domain=.${hostname}`;
};

/**
 * Returns contents of cookie
 * @param  {string} key         The key or identifier for the store
 * @param  {string} cookie      Custom cookie string to read
 * @returns {string | null}     Returns a string if exists
 */
export const getCookie = (key: string, customCookie?: string): string | null => {
    if (isServer()) {
        console.error('🚨 Accessing cookies from server');
        return null;
    }
    const cookie = customCookie || document.cookie;
    if (!cookie) {
        return null;
    }
    const b = document.cookie.match(`(^|[^;]+)\\s*${key}\\s*=\\s*([^;]+)`);
    return (b ? b.pop() : null) || null;
};

export const readFromCookie = (value: string, cookie: string): string | null => {
    if (isServer()) {
        console.error('🚨 Accessing cookies from server');
        return null;
    }

    if (!cookie) {
        return null;
    }

    const b = cookie.match(`(^|[^;]+)\\s*${value}\\s*=\\s*([^;]+)`);

    if (!b) {
        return null;
    }

    const bPOP = b.pop();

    if (!bPOP) {
        return '';
    }

    return bPOP;
};

/**
 * Returns contents of a local storage item
 * @param  {string} key         The key or identifier for the store
 * @param  {any} value       The value to store
 * @returns {void}
 */
export const saveToLocalStorage = (key: string, value: any): void => {
    try {
        localStorage.setItem(key, value);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
    }
};

/**
 * Returns contents of a local storage item
 * @param  {string} key         The key or identifier for the store
 * @returns {string | null}
 */
export const readFromLocalStorage = (key: string): any => {
    try {
        const result = localStorage.getItem(key);
        return result;
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        return null;
    }
};
export const copyToClipboard = async (text: string, onSuccess: () => void) => {
    navigator.clipboard.writeText(text);

    // Check for successful copy
    const copiedText = await navigator.clipboard.readText();
    if (copiedText === text) {
        onSuccess();
    }
};

export const saveLocaleAsCookie = (locale: string): void => {
    setCookie(COOKIE_ID.NOON_LOCALE, locale);
};

/**
 * Adds an item to a localStorage item that contains an array
 * @param  {string | Record<string, string>} arrayItemtoBeAdded        Item to be added
 * @param  {string} localStorageId                                     LocalStorage item key
 * @param  {number} maxArrayCount                                      Maximum length of array in cookie
 * @returns {void}
 */
export const addArrayItemToLocalStorage = (
    arrayItemtoBeAdded: string | Record<string, string>,
    localStorageId: string,
    maxArrayCount: number,
): void => {
    try {
        if (arrayItemtoBeAdded) {
            let processedArrayItem = arrayItemtoBeAdded;
            let items = JSON.parse(readFromLocalStorage(localStorageId));

            // Step 1 : Sanitize strings
            if (isString(arrayItemtoBeAdded)) {
                processedArrayItem = sanitizeText(arrayItemtoBeAdded);
            }
            // Step 2: Manipulate cookie contents
            if (items && Array.isArray(items)) {
                if (!items.some((item) => isEqual(item, processedArrayItem))) {
                    items.unshift(processedArrayItem);
                    if (items.length > maxArrayCount) {
                        items.pop();
                    }
                }
            } else {
                items = [arrayItemtoBeAdded];
            }
            saveToLocalStorage(localStorageId, JSON.stringify(items));
        }
    } catch (exception) {
        saveToLocalStorage(localStorageId, JSON.stringify([arrayItemtoBeAdded]));
    }
};

/**
 * Gets parsed array from localstorage item that stores an array
 * @param  {string} localStorageId            localstorage identifier
 * @returns {Array<any>}
 */
export const getArrayFromLocalStorage = (localStorageId: string): Array<any> => {
    if (typeof window === 'undefined') {
        return [];
    }

    if (readFromLocalStorage(localStorageId)) {
        let items;
        try {
            items = JSON.parse(readFromLocalStorage(localStorageId));
        } catch (e) {
            // Error in parsing
            return [];
        }

        if (items && items.length) {
            return [...items];
        }
    }
    return [];
};

export const getTimeLeft = (
    timestamp: number,
):
    | {
          hours: number;
          minutes: number;
          seconds: number;
      }
    | false => {
    const currentTime = Date.now() / 1000;
    if (currentTime > timestamp) return false;

    let seconds = Math.floor(timestamp - currentTime);
    let minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    const hoursLeftToday = hours - days * 24;
    minutes = minutes - days * 24 * 60 - hoursLeftToday * 60;
    seconds = seconds - days * 24 * 60 * 60 - hoursLeftToday * 60 * 60 - minutes * 60;

    return {
        hours,
        minutes,
        seconds,
    };
};

export const getEnConversionCurrency = (conversionCurrency: string): TOtherCountryCurrencyEN => {
    const currencyToEn = {
        USD: 'USD',
        AED: 'AED',
        SAR: 'SAR',
        KWD: 'KWD',
        QAR: 'QAR',
        BHD: 'BHD',
        OMR: 'OMR',
        'د.إ.': 'AED',
        'ر.س.': 'SAR',
        'د.ك': 'KWD',
        'ر.ق': 'QAR',
        'د.ب': 'BHD',
        'ر.ع': 'OMR',
    };

    return currencyToEn[conversionCurrency as keyof typeof currencyToEn] as TOtherCountryCurrencyEN;
};

type TConvertPrice = {
    conversionRates: TConversionRates;
    toCurrency: string;
    price: number;
    fromCurrency?: string;
};

const fromLocalCurrencyToUSD = ({
    conversionRates,
    fromCurrency,
    isUaeOrSa,
    price,
}: Omit<TConvertPrice, 'toCurrency'> & {
    isUaeOrSa?: boolean;
}): number => {
    let newPrice = price;

    if (isUaeOrSa) {
        newPrice = price * conversionRates?.[fromCurrency as keyof TConversionRates]?.USD;
    } else {
        const rate = conversionRates?.USD?.[fromCurrency as keyof TConversionRates];
        newPrice = price / rate;
    }

    return newPrice;
};

/**
 * This function doesn't convert from AED or SAR to other GCC currencies.
 * Price passed in is usually from API response, which returns a price whose currency is specific to its locale in the API request
 * Hence, toCurrency is only mandatory as it will decide whether a conversion will be done
 * fromCurrency is optional because countries other than UAE and KSA, currencyCode will be USD
 */
export const convertPrice = ({ conversionRates, fromCurrency = 'USD', price, toCurrency }: TConvertPrice): number => {
    let newPrice = price;
    if (price && Object.keys(conversionRates).length) {
        const otherCountries: TOtherCountryCurrencyEN[] = ['KWD', 'QAR', 'BHD', 'OMR'];
        const enToCurrency = getEnConversionCurrency(toCurrency);

        const isUaeOrSa = fromCurrency === 'AED' || fromCurrency === 'SAR';

        if (fromCurrency === 'USD' && otherCountries.includes(enToCurrency)) {
            newPrice = price * conversionRates?.USD?.[enToCurrency as keyof TConversionRates];
        } else if (enToCurrency === 'USD') {
            newPrice = fromLocalCurrencyToUSD({
                conversionRates,
                fromCurrency,
                isUaeOrSa,
                price,
            });
        }
    }
    return newPrice;
};

export const getPathname = () => {
    const { pathname, search } = window.location;
    const pathArray = pathname.split('/').filter((ele) => !!ele);

    let pathWithoutLocale = '/';

    if (pathArray.length) {
        const restOfPath = pathArray.slice(1);

        if (restOfPath.length) {
            pathWithoutLocale = `/${restOfPath.join('/')}`;

            if (search) {
                pathWithoutLocale = `${pathWithoutLocale}${search}`;
            }
        }
    }

    return pathWithoutLocale;
};
export const isHomePage = (url: string) =>
    url === '/men' ||
    url === '/women' ||
    url === '/kids' ||
    url === '/womens-beauty' ||
    url === '/premium-store' ||
    url === '/sports-store'
        ? 'home'
        : '';

const getSmallerTimerSale = (current: Timer, smallest: Partial<Timer>): Timer => {
    let res = current;
    if (smallest && smallest?.endDate) {
        const currEndDate = new Date(current.endDate);
        const smallEndDate = new Date(smallest.endDate);
        if (currEndDate > smallEndDate) {
            res = smallest as Timer;
        }
    }
    return res;
};

export const isTimerSaleActive = (timerSale?: Timer): boolean => {
    let isActive = false;
    if (timerSale && timerSale?.endDate) {
        const currentDate = new Date();
        const startDate = new Date(timerSale.startDate);
        const endDate = new Date(timerSale.endDate);

        isActive = startDate <= currentDate && endDate > currentDate && startDate < endDate;
    }

    return isActive;
};

export const getCurrentTimerSale = (timerSales?: Timer[]): Timer => {
    let res: Partial<Timer> = {};

    if (timerSales?.length) {
        res = timerSales.reduce((smallestTimerSale, currTimerSale) => {
            let smallest = currTimerSale;
            if (isTimerSaleActive(currTimerSale)) {
                smallest = getSmallerTimerSale(currTimerSale, smallestTimerSale);
            }
            return smallest;
        }, {});
    }

    return res as Timer;
};

/**
 * @param {string} stringToUpdate String whose value needs to be replaced
 * @param {number} index Index at which the string's char/chars needs to be replaced
 * @param {string} replacement The replacement string
 * @returns {string} New string
 */
export const replaceStringAt = (stringToUpdate: string, index: number, replacement: string): string => {
    if (index > stringToUpdate.length - 1) {
        return stringToUpdate;
    }
    return stringToUpdate.substring(0, index) + replacement + stringToUpdate.substring(index + 1);
};

/**
 * @param {array} variants Product variants
 * @returns {boolean} is OS size
 */
export const isOneSize = (variants: ProductFullResponse['variants']): boolean =>
    variants?.length === 1 && variants?.[0]?.title?.toLowerCase() === 'os';

/**
 * @param {ProductVariant} variant Product variant
 * @param {ProductFullResponse | ProductWishlistResponse} product Product
 * @returns {number} Product price
 */
export const getProductPrice = ( product: TSuperProduct, variant?: ProductVariant): number =>
    variant?.salePrice || variant?.normalPrice || product.salePrice || product.normalPrice;

/**
 * @param {string} url
 * @returns {string} url with trailing slash
 */
export const addTrailingSlash = (url: string) => url.replace(/\/?(\?|#|$)/, '/$1');

/**
 * @param {number} price
 * @returns {number} formatted price
 */
export const formatPrice = (value: number) => {
    if (value - Math.floor(value) === 0) {
        return `${value}`;
    }
    return value.toFixed(2);
};

/**
 * @param {object} product product data
 * @param {object} selectedVariant selected variant
 * @returns {object} {normalPrice, salePrice, discountPercent}
 */
export const getSelectedPrice = (
    product: ProductFullResponse,
    selectedVariant?: ProductVariant,
): { normalPrice: number; salePrice?: number; discountPercent: number } => {
    if (selectedVariant) {
        const { discountPercent, normalPrice, salePrice } = selectedVariant;
        return { normalPrice, salePrice, discountPercent };
    }

    const { discountPercent, normalPrice, salePrice } = product;
    return { normalPrice, salePrice, discountPercent };
};

/**
 * @returns {boolean} is safari
 */
export const isSafari = () =>
    /^((?!chrome|android).)*safari/i.test(navigator?.userAgent) && navigator?.userAgent.indexOf('Version/') > -1;

/**
 * @param {string} path browser path without locale
 * @returns {string} app deep link
 */
export const getAppDeepLink = (path: string) => {
    const url = new URL(path, `https://jhrp.adj.st`);
    url.searchParams.append('adj_deep_link', `namshi://n${path}`);

    Object.entries(APP_NUDGE_URL_PARAMS).forEach(([key, value]) => {
        url.searchParams.append(key, value);
    });

    const urlEncoded = url.toString();

    return urlEncoded;
};

/**
 * @param {NavDepartmentsResponse} navigationData
 * @param {string} selectedDepartmentId
 *  @param {string} selectedSubDepartmentId
 * @returns {string} redirected url
 */

export const getTabHref = (
    navigationData: NavDepartmentsResponse,
    selectedDepartmentId?: string,
    selectedSubDepartmentId?: string,
): string => {
    let tabHref;
    for (const department of navigationData.departments) {
        const { subDepartments, uri } = department;

        if (department.id === selectedDepartmentId) {
            if (subDepartments && subDepartments.length > 0) {
                const subDept = subDepartments.find(
                    ({ id: subDepartmentId }) => subDepartmentId === selectedSubDepartmentId,
                );

                tabHref = subDept?.uri || subDepartments[0]?.uri;
            } else {
                tabHref = uri;
            }
        }
    }
    return tabHref as string;
};
