import { CancelItemRequest, CancelShipmentRequest } from '@nm-namshi-frontend/services';

export enum TReasonCode {
    NONE = '',
    WPN = 'WPN',
    OPM = 'OPM',
    STH = 'STH',
    FC = 'FC',
    FCSE = 'FCSE',
    NR = 'NR',
    OT = 'OT',
}

export type TItemUpdates = {
    isSubmitted?: boolean;
    cancelComment?: string;
    cancelReasonCode?: TReasonCode;
    cancelReasonName?: string;
};

export type TReasons = {
    isSubmitted: boolean;
    comment: string;
    reasonCode: TReasonCode;
    itemNr: string;
    isCancelReasonsOpen: boolean;
    reasonName: string;
    onReasonsSectionExpand: () => void;
    onReasonCommentInput: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onReasonSelect: (code: TReasonCode, name: string) => void;
    onReasonDataSubmit: () => void;
};

export type TCancelAll = {
    orderNr: string;
    cancelReasonCode: TReasonCode;
    cancelReasonName: string;
    cancelComment: string;
    isSubmitted: boolean;
};

export interface ICancelItemRequest extends CancelItemRequest {
    cancelReasonName?: string;
    isSubmitted?: boolean;
}

export interface ICancelShipmentRequest extends CancelShipmentRequest {
    cancelReasonName?: string;
    isSubmitted?: boolean;
}
