export enum EPaymentIntentStatus {
    PaymentPending = 'payment_pending',
    PaymentReadyToRedirect = 'payment_ready_to_redirect',
    PaymentCancelled = 'payment_cancelled',
    PaymentConfirmed = 'payment_confirmed',
    PaymentCaptured = 'payment_captured',
    PaymentRejected = 'payment_rejected',
    PaymentReversed = 'payment_reversed',
    PaymentRefunded = 'payment_refunded',
    PaymentExpired = 'payment_expired',
    PaymentFailed = 'payment_failed',
    SubscriptionConfirmed = 'subscription_confirmed',
}

export enum EPaymentMethodCodes {
    CreditCard = 'cc_noonpay',
    CashOnDelivery = 'cod',
    NoonCredits = 'credits',
    Tabby = 'tabby',
    Tamara = 'tamara',
}

export type TPaymentIcons = 'mastercard' | 'visa' | 'cod' | 'mada' | 'amex' | 'tabby' | 'tamara' | 'apple-pay';

export enum CheckoutButtonState {
    PLACE_ORDER = 'place-order',
    TAMARA = 'Tamara',
    TABBY = 'tabby',
    ENTER_CVV = 'enter-cvv',
    SELECT_METHOD = 'select-payment-method',
    ADD_NEW_CARD = 'add-new-card',
    SET_ADDRESS = 'set-address',
}
