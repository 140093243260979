import { ClientApp, TRegionSEO, TCountryConfig, TStoreSelectionConfig, TSubDepartmentId } from './types';

// FYI - this is probably somewhat temporary since it will come from webConfig api later
export const CDN_BASE = 'https://f.nooncdn.com';
export const IMG_BASE = `${CDN_BASE}/s/app/com`;

export const MARKETPLACE = 'namshi';

export const BASE_HEADERS = {
    'x-mp': 'namshi_v2',
    'x-platform': 'web',
    'x-cms': 'v2',
};

export const ACCEPTED_IMAGES_DOMAINS = ['k.nooncdn.com', 'z.nooncdn.com', 'f.nooncdn.com', 'a.nooncdn.com', 'a.namshicdn.com']

export const GOOGLE_MAP_API_KEY = `${process.env.GMAPS_API_KEY || 'AIzaSyANWPf72r_A0rSYpmnXJ_1J4MRIhCswK0c'}`;

export const BIASING_RADIUS = 20000; // RADIUS IN METRES TO BIAS SEARCH RESULTS

export const MAP_CONFIG = {
    centralGeoLocations: {
        ae: {
            lat: 252048201,
            lng: 552707501,
            area_en: 'Dubai',
            area_ar: 'دبي',
        }, // Dubai
        sa: {
            lat: 247115201,
            lng: 466732959,
            area_en: 'Jeddah',
            area_ar: 'جدة',
        }, // Jeddah

        // TODO: Update correct data
        kw: {
            lat: 293785860,
            lng: 479903410,
            area_en: 'Kuwait City',
            area_ar: 'الكويت',
        }, // Kuwait City
        qa: {
            lat: 252861060,
            lng: 515348170,
            area_en: 'Doha',
            area_ar: 'الدوحة',
        }, //  Doha
        bh: {
            lat: 262448460,
            lng: 505865860,
            area_en: 'Manama',
            area_ar: 'الْمَنَامَة',
        }, //  Manama
        om: {
            lat: 236143280,
            lng: 585452840,
            area_en: 'Muscat',
            area_ar: 'مَسْقَط',
        }, //  Muscat
    },
    defaultZoom: 14,
    precision: 10000000,
    biasingRadius: 20000,
    icons: {
        lockerPoint: `${IMG_BASE}/namshi/icons/map_collection-locker.svg`,
        pickupPoint: `${IMG_BASE}/namshi/icons/map_collection-pickup.svg`,
        mapLandmarkPin: `${IMG_BASE}/namshi/icons/locationPin.svg`,
        redLivePin: `${IMG_BASE}/namshi/icons/driver-pin.svg`,
        homePin: `${IMG_BASE}/namshi/icons/home-pin-1.svg`,
    },
};

export const ANALYTICS = {
    gtmID: 'GTM-TSWDBM3L',
};

export const BLOCK_LATLNG_TO_LOCKER_ADDRESS_CONVERSION = true;

export const BASE_URL = 'https://www.namshi.com/';

export const REGIONCONFIG: Record<TRegionSEO, TCountryConfig> = {
    // Do not reorder
    saudi: {
        titleEn: 'Saudi Arabia',
        codLimit: 5000,
        chargedInUSD: false,
        enabled: true,
        freeDeliveryCap: 100,
        paymentIcons: ['mastercard', 'visa', 'mada', 'cod', 'tabby', 'tamara', 'apple-pay'],
        supportEmail: 'care@namshi.com',
        supportPhone: '+966 118297568',
        languages: ['en', 'ar'],
        showNoonPickupPoints: false,
        showNoonLockers: true,
        codes: {
            seo: 'saudi',
            api: 'sa',
            iso: 'SA',
        },
        currencies: {
            en: 'SAR',
            ar: 'ر.س.',
        },
        creditCardTypes: ['VISA', 'MASTERCARD', 'MADA'],
        dialingCode: '+966',
        paymentMethods: {
            tamara: {
                isActive: true,
            },
            tabby: {
                isActive: true,
            },
            cc: {
                isActive: true,
            },
            cod: {
                isActive: true,
            },
        },
        flagImageUrl: `${IMG_BASE}/namshi/images/flag-saudi.svg`,
        timezone: {
            name: 'Asia/Riyadh',
            offset: 'GMT+3',
        },
    },
    uae: {
        titleEn: 'United Arab Emirates',
        codLimit: 5000,
        chargedInUSD: false,
        enabled: true,
        freeDeliveryCap: 100,
        paymentIcons: ['mastercard', 'visa', 'cod', 'tabby', 'tamara', 'apple-pay'],
        supportEmail: 'care@namshi.com',
        supportPhone: '+971 800626744',
        languages: ['en', 'ar'],
        showNoonPickupPoints: false,
        showNoonLockers: true,
        codes: {
            seo: 'uae',
            api: 'ae',
            iso: 'AE',
        },
        currencies: {
            en: 'AED',
            ar: 'د.إ.',
        },
        creditCardTypes: ['VISA', 'MASTERCARD'],
        dialingCode: '+971',
        paymentMethods: {
            tamara: {
                isActive: true,
            },
            tabby: {
                isActive: true,
            },
            cc: {
                isActive: true,
            },
            cod: {
                isActive: true,
            },
        },
        flagImageUrl: `${IMG_BASE}/namshi/images/flag-uae.svg`,
        timezone: {
            name: 'Asia/Dubai',
            offset: 'GMT+4',
        },
    },
    kuwait: {
        titleEn: 'Kuwait',
        codLimit: 5000,
        chargedInUSD: true,
        enabled: true,
        freeDeliveryCap: 100,
        paymentIcons: ['mastercard', 'visa', 'cod', 'apple-pay'],
        supportEmail: 'care@namshi.com',
        languages: ['en', 'ar'],
        showNoonPickupPoints: false,
        showNoonLockers: false,
        codes: {
            seo: 'kuwait',
            api: 'kw',
            iso: 'KW',
        },
        currencies: {
            en: 'KWD',
            ar: 'د.ك',
        },
        creditCardTypes: ['VISA', 'MASTERCARD', 'MADA'],
        dialingCode: '+965',
        paymentMethods: {
            tamara: {
                isActive: false,
            },
            tabby: {
                isActive: false,
            },
            cc: {
                isActive: true,
            },
            cod: {
                isActive: true,
            },
        },
        flagImageUrl: `${IMG_BASE}/namshi/images/flag-kuwait.svg`,
        timezone: {
            name: 'Asia/Kuwait',
            offset: 'GMT+3',
        },
    },
    qatar: {
        titleEn: 'Qatar',
        codLimit: 5000,
        chargedInUSD: true,
        enabled: true,
        freeDeliveryCap: 100,
        paymentIcons: ['mastercard', 'visa', 'cod', 'apple-pay'],
        supportEmail: 'care@namshi.com',
        languages: ['en', 'ar'],
        showNoonPickupPoints: false,
        showNoonLockers: false,
        codes: {
            seo: 'qatar',
            api: 'qa',
            iso: 'QA',
        },
        currencies: {
            en: 'QAR',
            ar: 'ر.ق',
        },
        creditCardTypes: ['VISA', 'MASTERCARD', 'MADA'],
        dialingCode: '+974',
        paymentMethods: {
            tamara: {
                isActive: false,
            },
            tabby: {
                isActive: false,
            },
            cc: {
                isActive: true,
            },
            cod: {
                isActive: true,
            },
        },
        flagImageUrl: `${IMG_BASE}/namshi/images/flag-qatar.svg`,
        timezone: {
            name: 'Asia/Qatar',
            offset: 'GMT+3',
        },
    },
    bahrain: {
        titleEn: 'Bahrain',
        codLimit: 5000,
        chargedInUSD: true,
        enabled: true,
        freeDeliveryCap: 100,
        paymentIcons: ['mastercard', 'visa', 'cod', 'apple-pay'],
        supportEmail: 'care@namshi.com',
        languages: ['en', 'ar'],
        showNoonPickupPoints: false,
        showNoonLockers: false,
        codes: {
            seo: 'bahrain',
            api: 'bh',
            iso: 'BH',
        },
        currencies: {
            en: 'BHD',
            ar: 'د.ب',
        },
        creditCardTypes: ['VISA', 'MASTERCARD', 'MADA'],
        dialingCode: '+973',
        paymentMethods: {
            tamara: {
                isActive: false,
            },
            tabby: {
                isActive: false,
            },
            cc: {
                isActive: true,
            },
            cod: {
                isActive: true,
            },
        },
        flagImageUrl: `${IMG_BASE}/namshi/images/flag-bahrain.svg`,
        timezone: {
            name: 'Asia/Bahrain',
            offset: 'GMT+3',
        },
    },
    oman: {
        titleEn: 'Oman',
        codLimit: 5000,
        chargedInUSD: true,
        enabled: true,
        freeDeliveryCap: 100,
        paymentIcons: ['mastercard', 'visa', 'cod', 'apple-pay'],
        supportEmail: 'care@namshi.com',
        languages: ['en', 'ar'],
        showNoonPickupPoints: false,
        showNoonLockers: false,
        codes: {
            seo: 'oman',
            api: 'om',
            iso: 'OM',
        },
        currencies: {
            en: 'OMR',
            ar: 'ر.ع',
        },
        creditCardTypes: ['VISA', 'MASTERCARD', 'MADA'],
        dialingCode: '+968',
        paymentMethods: {
            tamara: {
                isActive: false,
            },
            tabby: {
                isActive: false,
            },
            cc: {
                isActive: true,
            },
            cod: {
                isActive: true,
            },
        },
        flagImageUrl: `${IMG_BASE}/namshi/images/flag-oman.svg`,
        timezone: {
            name: 'Asia/Muscat',
            offset: 'GMT+4',
        },
    },
};

export const STORE_SELECTION_CONFIG: TStoreSelectionConfig[] = [
    {
        title: {
            en: 'Saudi Arabia',
            ar: 'المملكة العربية السعودية',
        },
        url: {
            en: 'saudi-en',
            ar: 'saudi-ar',
        },
        locale: {
            en: 'en-sa',
            ar: 'ar-sa',
        },
        imageUrl: `${IMG_BASE}/namshi/images/flag-saudi.svg`,
        isClosed: false,
    },
    {
        title: {
            en: 'United Arab Emirates',
            ar: 'الامارات العربية المتحدة',
        },
        url: {
            en: 'uae-en',
            ar: 'uae-ar',
        },
        locale: {
            en: 'en-ae',
            ar: 'ar-ae',
        },
        imageUrl: `${IMG_BASE}/namshi/images/flag-uae.svg`,
        isClosed: false,
    },
    {
        title: {
            en: 'Kuwait',
            ar: 'الكويت',
        },
        url: {
            en: 'kuwait-en',
            ar: 'kuwait-ar',
        },
        locale: {
            en: 'en-kw',
            ar: 'ar-kw',
        },
        imageUrl: `${IMG_BASE}/namshi/images/flag-kuwait.svg`,
        isClosed: false,
    },
    {
        title: {
            en: 'Qatar',
            ar: 'قطر',
        },
        url: {
            en: 'qatar-en',
            ar: 'qatar-ar',
        },
        locale: {
            en: 'en-qa',
            ar: 'ar-qa',
        },
        imageUrl: `${IMG_BASE}/namshi/images/flag-qatar.svg`,
        isClosed: false,
    },
    {
        title: {
            en: 'Bahrain',
            ar: 'البحرين',
        },
        url: {
            en: 'bahrain-en',
            ar: 'bahrain-ar',
        },
        locale: {
            en: 'en-bh',
            ar: 'ar-bh',
        },
        imageUrl: `${IMG_BASE}/namshi/images/flag-bahrain.svg`,
        isClosed: false,
    },
    {
        title: {
            en: 'Oman',
            ar: 'عمان',
        },
        url: {
            en: 'oman-en',
            ar: 'oman-ar',
        },
        locale: {
            en: 'en-om',
            ar: 'ar-om',
        },
        imageUrl: `${IMG_BASE}/namshi/images/flag-oman.svg`,
        isClosed: false,
    },
    {
        title: {
            en: 'Iraq',
            ar: 'العراق',
        },
        imageUrl: `${IMG_BASE}/namshi/images/flag-iraq.svg`,
        isClosed: true,
    },
    {
        title: {
            en: 'Rest Of The World',
            ar: 'بقية أنحاء العالم',
        },
        iconName: 'restOfTheWorld',
        isClosed: true,
    },
];

export const RECENT_SEARCHES_MAX_COUNT = 6;
export const RECENTLY_VIEWED_ITEMS_MAX_COUNT = 20;
export const RECENTLY_VIEWED_PRODUCT_COUNT_IN_SEARCH_SUGGESTIONS = 4;

// ---------------------------CLEAN THE BELOW POST HEADER DEPARTMENT TABS ROLLOUT for mweb + dweb--------------------------- //

export const DEFAULT_DEPARTMENT_CODE = 'women';
export const DEFAULT_SUBDEPARTMENT_CODE = 'for-you';

export const NON_CATALOG_ALL_SUBDEPARTMENT = 'all'; // Adding this into the config(temporarily), as it is hardcoded in the component

export const DEPARTMENTS_CONFIG = [
    // Added linkUrlV2, departmentTabId, subDepartmentId so that we use it in in the interim
    // Plan is to eventually get all this content from onsite
    {
        code: 'women',
        linkUrl: `/women/`,
        linkUrlV2: '/fashion/women-fashion/', // Temporary, until consolidate the behaviors or navigation-categories across the app
        departmentTabId: 'fashion', // Temporary, until consolidate the behaviors or navigation-categories across the app
        subDepartmentId: 'fashion-women', // Temporary, until consolidate the behaviors or navigation-categories across the app
        linkText: {
            en: 'Women',
            ar: 'النساء',
        },
        imageUrl: {
            en: `${IMG_BASE}/namshi/images/desktop-women-v3.jpg`,
            ar: `${IMG_BASE}/namshi/images/desktop-women-v3.jpg`,
        },
        imageUrlMob: {
            en: `${CDN_BASE}/noon-cdn/namshi-cms/feed-selection/20231130/mobile-tile/women.jpg`,
            ar: `${CDN_BASE}/noon-cdn/namshi-cms/feed-selection/20231130/mobile-tile/women.jpg`,
        },
        iconUrl: `${IMG_BASE}/namshi/images/tile-women-v3.jpg`,
        showOnHomeBanner: true,
        enabledForCountries: ['uae', 'saudi', 'kuwait', 'qatar', 'bahrain', 'oman'],
        isSearchable: true,
    },
    {
        code: 'men',
        linkUrl: `/men/`,
        linkUrlV2: '/fashion/men-fashion/', // Temporary, until consolidate the behaviors or navigation-categories across the app
        departmentTabId: 'fashion', // Temporary, until consolidate the behaviors or navigation-categories across the app
        subDepartmentId: 'fashion-men', // Temporary, until consolidate the behaviors or navigation-categories across the app
        linkText: {
            en: 'Men',
            ar: 'الرجال',
        },
        imageUrl: {
            en: `${IMG_BASE}/namshi/images/desktop-men-v3.jpg`,
            ar: `${IMG_BASE}/namshi/images/desktop-men-v3.jpg`,
        },
        imageUrlMob: {
            en: `${CDN_BASE}/noon-cdn/namshi-cms/feed-selection/20231130/mobile-tile/men.jpg`,
            ar: `${CDN_BASE}/noon-cdn/namshi-cms/feed-selection/20231130/mobile-tile/men.jpg`,
        },
        iconUrl: `${IMG_BASE}/namshi/images/tile-men-v3.jpg`,
        showOnHomeBanner: true,
        enabledForCountries: ['uae', 'saudi', 'kuwait', 'qatar', 'bahrain', 'oman'],
        isSearchable: true,
    },
    {
        code: 'kids',
        linkUrl: `/kids/`,
        linkUrlV2: '/kids/kids-fashion/', // Temporary, until consolidate the behaviors or navigation-categories across the app
        departmentTabId: 'kids', // Temporary, until consolidate the behaviors or navigation-categories across the app
        subDepartmentId: 'kids', // Temporary, until consolidate the behaviors or navigation-categories across the app
        linkText: {
            en: 'Kids',
            ar: 'الأطفال',
        },
        imageUrl: {
            en: `${IMG_BASE}/namshi/images/desktop-kids-v3.jpg`,
            ar: `${IMG_BASE}/namshi/images/desktop-kids-v3.jpg`,
        },
        imageUrlMob: {
            en: `${CDN_BASE}/noon-cdn/namshi-cms/feed-selection/20231130/mobile-tile/kids.jpg`,
            ar: `${CDN_BASE}/noon-cdn/namshi-cms/feed-selection/20231130/mobile-tile/kids.jpg`,
        },
        iconUrl: `${IMG_BASE}/namshi/images/tile-kids-v3.jpg`,
        showOnHomeBanner: true,
        enabledForCountries: ['uae', 'saudi', 'kuwait', 'qatar', 'bahrain', 'oman'],
        isSearchable: true,
    },
];

// Due to inconsistent search experience, we need this mapping to resolve the search subdepartments
// Temporary, until consolidate the behaviors or navigation-categories across the app
export const V1_SEARCH_DEPARTMENTS_CONFIG = [
    {
        departmentIdV2: 'fashion',
        subDepartmentIdV2: 'fashion-women',
        searchSubDepartmentV1: 'women',
    },
    {
        departmentIdV2: 'fashion',
        subDepartmentIdV2: 'fashion-men',
        searchSubDepartmentV1: 'men',
    },
    {
        departmentIdV2: 'beauty',
    },
    {
        departmentIdV2: 'kids',
    },
    {
        departmentIdV2: 'home',
    },
] as Array<{
    departmentIdV2: 'fashion';
    subDepartmentIdV2?: 'fashion-women';
    searchSubDepartmentV1?: TSubDepartmentId;
}>;

// ---------------------------@Todo - Ehab : CLEAN THE ABOVE POST HEADER DEPARTMENT TABS ROLLOUT for mweb + dweb--------------------------- //

export const ACCOUNT_NAV_CONFIG = [
    {
        code: 'orders',
        path: ['/orders'] as Array<string>,
        titleKey: 'nav.orders',
    },
    {
        code: 'returns',
        path: ['/exchanges', '/returns'] as Array<string>,
        titleKey: 'nav.exchanges-&-returns',
    },
    {
        code: 'credits',
        path: ['/credits'] as Array<string>,
        titleKey: 'nav.wallet',
    },
    {
        code: 'coupons',
        path: ['/coupons'] as Array<string>,
        titleKey: 'nav.coupons',
    },
    {
        code: 'addresses',
        path: ['/addresses'] as Array<string>,
        titleKey: 'nav.delivery-addresses',
    },
    {
        code: 'payments',
        path: ['/saved-cards'] as Array<string>,
        titleKey: 'nav.payment-cards',
    },
    {
        code: 'profile',
        path: ['/'] as Array<string>,
        titleKey: 'nav.profile',
    },
] as const;

export const BNPL_INSTALLMENT_COUNT = 4;

export const TABBY_INSTALLMENT_COUNT = 4; // Acc to Product + BE , this will be a constant for the foreseeable future
export const TABBY_DEFAULT_PRODUCT = 'installments'; // Acc to Product + BE , this will be a constant for the foreseeable future
export const TABBY_LEARN_MORE_PATH = '/tabby-learn-more';
export const TABBY_LEARN_MORE_EXTERNAL_URL = 'https://checkout.tabby.ai/promos/product-page/installments';

// Tamara (For now)
export const TAMARA_MONTHS = 3;
export const TAMARA_PUBLIC_KEY = '860b265a-fe9b-4d05-8f8b-b2ed4e17c9c2';
export const TAMARA_DEFAULT_PRODUCT = 'PAY_BY_INSTALMENTS';
export const TAMARA_INSTALLMENT_COUNT = 4;
export const TAMARA_LEARN_MORE_CDN_BASE_URL = 'https://cdn.tamara.co/widget-v2/tamara-widget.html';
export const TAMARA_WIDGET_TYPE = {
    INFORMATION_WIDGET: 'information-widget',
    PRODUCT_WIDGET: 'product-widget',
    INSTALLMENT_PLAN: 'installment-plan',
};

export const APPSTORE_LINK = 'https://itunes.apple.com/us/app/namshi-online-fashion-shopping/id840127349?mt=8';

export const PLAYSTORE_LINK = 'https://play.google.com/store/apps/details?id=com.namshi.android';

export const HUAWEI_APP_GALLERY_LINK = 'https://appgallery.huawei.com/app/C100071951';

export const INSTAGRAM_LINK = 'https://instagram.com/namshi/';

export const FACEBOOK_LINK = 'https://www.facebook.com/NamshiOfficial/';

export const TWITTER_LINK = 'https://twitter.com/NamshiDotCom';

export const PINTEREST_LINK = 'https://www.pinterest.com/namshidotcom/';

export const YOUTUBE_LINK = 'https://www.youtube.com/namshi/';

export const SNAPCHAT_LINK = 'https://www.snapchat.com/add/namshiofficial/';

export const NAMSHI_LINKEDIN = 'https://www.linkedin.com/company/namshi-com';

export const TIKTOK_LINK = 'https://www.tiktok.com/';

export const ABOUT_US = '/about';

export const PARTNERSHIPS = '/about/partnerships';

export const BRANDS_AZ = '/brands';

export const PRESS = '/press';

export const CAREERS = '/careers';

export const SIZE_GUIDE = '/size-guide';

export const TRENDS_OF_SEASON = '/end-of-season-sale';

export const BACK_TO_SCHOOL_OFFERS = '/back-to-school';

export const WOMENS_CLOTHIN = '/women-clothing';

export const MENS_CLOTHING = '/men-clothing';

export const MENS_SNEAKERS = '/men-shoes-sneakers';

export const WOMENS_SNEAKERS = '/women-shoes-sneakers';

export const WOMENS_SHOES = '/women-shoes';

export const TSHIRTS = '/men-clothing-tshirts_vests';

export const MAKEUP = '/women-beauty-products-makeup';

export const DISCOUNT_COUPON = '/coupon';

export const GINGER = '/ginger';

export const ADIDAS = '/adidas-collection';

export const NIKE = '/search?f[brand_code]=nike';

export const SEVENTY_FIVE = '/seventy_five';

export const LACOSTE = '/search?f[brand_code]=lacoste';

export const WARRANTY_POLICY = '/warranty-policy';

export const TERMS_OF_USE = '/terms-of-use';

export const TERMS_OF_SALE = '/terms-of-sale';

export const PRIVACY_POLICY = '/privacy-policy';

export const CONSUMER_RIGHTS = '/consumer-rights';

export const NAMSHI_ACCOUNT_PAGE_FALLBACK_URL = 'https://account.namshi.com/';

export const NOON_TERMS = (locale: string) => `https://www.noon.com/${locale}/terms-of-use/`;

export const NOON_PRIVACY = (locale: string) => `https://www.noon.com/${locale}/privacy-policy/`;

export const FIREBASE_APP_DYNAMIC_LINK = 'https://sivviapp.page.link/women?efr=1'; // Used for BannerModal widgets : ideally this should be configured from the catalog content side ÷

export const SENTRY_DSN = {
    bigalog: 'https://c12d3930b9e914b92e4dc080cfc64873@o135813.ingest.sentry.io/4505622326607872',
    schmatalog: 'https://430acc70a84faaace9e70da976c00683@o135813.ingest.sentry.io/4505622330212352',
    account: 'https://22b436d1020f6201e3dd2fcb119c2a5f@o135813.ingest.sentry.io/4505622331981824',
} as Record<ClientApp, string>;

export const DEFAULT_SEO_META = {
    title: 'Namshi Online Shopping | Clothes, Dresses, Shoes & Bags',
    description:
        "Best Price & Easy Online Shopping for Men's T-Shirts & Vests Clothing ✓ 20% OFF First Order on App use CODE FIRST ✓ 2000+ Brands ✓ 100% Contactless ✓ Same Day Delivery ✓ Cash on Delivery ✓ Easy Free Returns",
};

export const SHARE_URL_UTM_CAMPAIGN = 'o24_all_web_both_organic_share_share-elements_open_na_na_na';

export const WARDROBE_SHARE_URL_UTM_CAMPAIGN =
    'o557_all_mweb_both_organic_share_share-elements_open_wishlist_wardrobe_share';

export const BRANDS = '/brands/';

export const WISHLIST_PARENTSKUS_FETCH_STALETIME = 10000 * 60000;

export const WIDGET_IDS = {
    PDP_SPONSORED_PRODUCTS: 15578,
    PDP_SIMILAR_PRODUCTS: 15577,
    PDP_HOW_ABOUT_THESE: 15579,
    CART_RECENTLY_VIEWED: 15580,
    RECENTLY_SEARCHED: 17518,
    SEARCH_RECENTLY_VIEWED: 17552,
    WISHLIST_RECENTLY_VIEWED: 17551,
    SIMILAR_PRODUCTS: 17521,
    FOLLOWING_BRANDS: 16319,
    POPULAR_BRANDS: 16320,
    BRAND_LIST: 16321,
    CROSS_SEARCH_CAROUSEL: 18026,
    PRODUCT_OPTIONS_PDP: 18314,
    PRODUCT_OPTIONS_MODAL_PDP: 18315,
    PRODUCT_OPTIONS_MODAL_PRODUCT_BOX: 18316,
    FREE_SAMPLE_TRAY: 18229,
};

export const APP_DEEPLINKS = {
    namshiLooks:
        'https://jhrp.adj.st/namshi-looks?adj_t=1cn22r5x_1cryhxis&adj_deep_link=namshi%3A%2F%2Fn%2Fnamshi-looks',
};
export const SAUDI_VAT = 310398596400003;
export const SAUDI_CR = 4030356009;

export const APP_NUDGE_URL_PARAMS = {
    adj_t: '1el0vr0b_1evy9im2',
    utm_source: 'onsite',
    utm_medium: 'banner',
    utm_campaign: 'd1_na_app_na_organic_onsite_banner_open_first_na_na',
    adj_campaign: 'd1_na_app_na_organic_onsite_banner_open_first_na_na',
};

export const HELP_CENTRE_BASEURL = 'https://help.namshi.com/portal/';
export const HELP_CENTRE_LINKS = {
    ar: {
        order_tracking: 'ar/kb/articles/تتبع-الطلبية',
        order_returns: 'ar/kb/الإسترجاع-والإستبدال',
        refund: 'ar/kb/articles/كيف-يمكنني-إسترداد-أموالي',
        info: 'ar/kb/articles/هل-تضمن-نمشي-أن-كافة-منتجاتها-أصلية-100-12-6-2024',
        delivery: 'ar/kb/articles/ما-هي-المدة-التي-يستغرقها-طلبي-في-الوصول-إلي-12-6-2024',
        payment_methods: 'ar/kb/articles/ما-هي-وسائل-الدفع-التي-تقبلون-بها-12-6-2024',
        privacy_policy: 'ar/kb/articles/الخصوصية-والسريّة-في-موقع-نمشي-12-6-2024',
        terms_and_conditions: 'ar/kb/شروط-استخدام-نمشي',
        home: 'ar/home',
        returns: 'ar/kb/articles/ما-هي-سياسة-إرجاع-المنتجات-في-نمشي',
        contactUs: 'ar/kb/articles/اتصل-بنا-12-6-2024',
    },
    en: {
        order_tracking: 'en/kb/articles/order-tracking',
        order_returns: 'en/kb/returns-and-exchange',
        refund: 'en/kb/articles/how-do-i-get-my-refund',
        info: 'en/kb/articles/does-namshi-guarantee-authenticity-of-products',
        delivery: 'en/kb/articles/how-long-does-it-take-to-receive-my-order',
        payment_methods: 'en/kb/articles/what-payment-methods-do-you-accept',
        privacy_policy: 'en/kb/articles/privacy-policy-for-namshi-com',
        terms_and_conditions: 'en/kb/namshi-terms-of-use',
        home: 'en/home',
        returns: 'en/kb/articles/what-is-namshi-s-return-policy',
        contactUs: 'en/kb/articles/contact-us',
    },
};
